



























































































import { mapActions, mapState, mapGetters } from 'vuex';
import { ClientsInfoState } from '@/store/modules/clients-info/ClientsInfoState';
import type { UsersItem } from '@/api/schema';
import OverallBalanceUser from '@/components/users/OverallBalanceUser.vue';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';
import { DataTableHeader } from 'vuetify';
import { textOverflowMiddle } from '@/helpers/formatString';

const defaultOptions = {
  page: 1,
  itemsPerPage: 10,
  sortBy: [],
  sortDesc: [false]
};

export default {
  name: 'UsersView',
  components: { OverallBalanceUser },
  data: (): unknown => ({
    searchPhrase: '',
    options: { ...defaultOptions },
    selectValue: 'operatorUserId',
    selectItems: [
      { text: 'Operator User ID', value: 'operatorUserId' },
      { text: 'Wallet Address', value: 'walletHashId' },
      { text: 'Wallet User ID', value: 'userId' }
    ]
  }),
  computed: {
    ...mapState('ClientsInfo', {
      clients: (state: ClientsInfoState) => state.users.users,
      total: (state: ClientsInfoState) => state.users.total,
      loadingUsers: (state: ClientsInfoState) => state.loadingUsers
    }),
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('app', ['isCopyStakeRole']),
    headers(): DataTableHeader[] {
      const hideColumnsSet = new Set(
        this.isCopyStakeRole
          ? ['staking_active', 'profit_mining_active', 'overallBalance']
          : []
      );

      const headers = [
        {
          text: 'Registration Date',
          value: 'id',
          align: 'left'
        },
        {
          text: 'Wallet User ID',
          value: 'walletUserid',
          sortable: false,
          align: 'right'
        },
        {
          text: 'Operator User ID',
          value: 'operatorUserId',
          sortable: false,
          align: 'right'
        },
        {
          text: 'Wallet Address',
          value: 'walletHash',
          align: 'right',
          sortable: false,
          maxWidth: 400
        },
        {
          text: 'H2E',
          value: 'staking_active',
          align: 'center'
        },
        {
          text: 'P2E',
          value: 'profit_mining_active',
          align: 'center'
        },
        {
          text: 'Overall Balance',
          value: 'overallBalance',
          width: 180,
          align: 'center',
          sortable: false
        }
      ];

      return headers.filter(
        (headerItem) => !hideColumnsSet.has(headerItem.value)
      ) as DataTableHeader[];
    },
    disableSearch(): boolean {
      const isValidSearchPhraseLength =
        this.selectValue === 'operatorUserId' ||
        this.searchPhrase?.length >= 3;

      return !(this.searchPhrase && isValidSearchPhraseLength);
    },

    searchParams(): any {
      const { sortBy, sortDesc } = this.options;

      return {
        page: this.currentPage,
        size: this.options.itemsPerPage,
        ...(sortBy.length && {
          sort: sortBy[0],
          sortDirection: sortDesc[0] ? 'desc' : 'asc'
        })
      };
    },

    currentPage(): number {
      return this.options.page - 1;
    }
  },
  watch: {
    options: {
      async handler(): Promise<void> {
        if (this.operatorId) {
          await this.updateUserList();
        }
      },
      deep: true
    },
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.options = { ...defaultOptions };
        }
      },
      immediate: true
    },
    searchPhrase(val: string): void {
      if (val) return;

      this.getUsersList({
        size: this.options.itemsPerPage,
        page: 0
      });
    }
  },
  methods: {
    ...mapActions('ClientsInfo', ['getUsersList']),
    textOverflowMiddle,
    copyToClipBoard,
    clearHandler(): void {
      this.searchPhrase = '';
      this.$nextTick((): void => {
        this.updateUserList();
      });
    },
    updateUserList(isSearch = false): void {
      this.getUsersList({
        ...this.searchParams,
        page: isSearch ? 0 : this.searchParams.page,
        ...(this.searchPhrase?.length
          ? { [this.selectValue]: this.searchPhrase }
          : {})
      });
    },
    rowClickHandler(data: UsersItem): void {
      if (this.isCopyStakeRole) return;

      this.$router.push({
        name: this.isSuperAdmin
          ? 'account-operator-token-user-management'
          : 'user-management',
        params: {
          userId: data.id.toString()
        }
      });
    }
  }
};
