











import { mapState } from 'vuex';
import { TokensInfoState } from '@/store/modules/tokens-info/TokensInfoState';
import { UserBalancesData } from '@/api/schema';

import Decimal from 'decimal.js-light';
import { getUserBalances } from '@/api/ClientInfo';
import numeralSpaces from '@/filters/numeral-spaces';

export default {
  name: 'OverallBalanceUser',

  props: {
    userId: {
      type: Number,
      default: null
    }
  },

  data(): any {
    return {
      balances: null,
      loading: false,
      show: false
    };
  },

  computed: {
    ...mapState('TokensInfo', {
      currencyTicker: (state: TokensInfoState) => state.tokenPrice.currency
    }),

    showBalance(): boolean {
      return this.show && !this.loading;
    },

    formattedBalance(): string {
      if (!this.balances) return;

      const walletBalance = new Decimal(this.balances.walletBalance || 0);
      const stakedBalance = new Decimal(this.balances.stakedBalance || 0);
      const balance = walletBalance.plus(stakedBalance).toString();

      return `${numeralSpaces(balance, '0,0.[00]')}  ${this.currencyTicker}`;
    }
  },

  methods: {
    toggleShow(): void {
      this.show = !this.show;

      if (this.balance) return;

      this.getBalance();
    },

    getBalance(): void {
      this.loading = true;

      getUserBalances(this.userId)
        .then((data: UserBalancesData): void => {
          this.balances = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
