var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h2',{staticClass:"text-h5 font-weight-medium mb-6"},[_vm._v("User Data")]),_c('v-card',[_c('v-row',{staticClass:"ma-0"},[_c('v-spacer'),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"12","md":"8"}},[_c('v-form',{staticClass:"flex-column flex-md-row align-stretch d-flex align-center",on:{"submit":function($event){$event.preventDefault();return (function () { return _vm.updateUserList(true); }).apply(null, arguments)}}},[_c('v-select',{staticClass:"search-type-select mb-3 mb-md-0 mr-md-6",attrs:{"items":_vm.selectItems,"outlined":"","hide-details":"","dense":""},model:{value:(_vm.selectValue),callback:function ($$v) {_vm.selectValue=$$v},expression:"selectValue"}}),_c('v-text-field',{staticClass:"mb-3 mb-md-0 mr-md-6",attrs:{"placeholder":" Search Operator User ID / Wallet Address","outlined":"","hide-details":"","dense":"","clearable":""},on:{"click:clear":_vm.clearHandler},model:{value:(_vm.searchPhrase),callback:function ($$v) {_vm.searchPhrase=$$v},expression:"searchPhrase"}}),_c('v-btn',{attrs:{"type":"submit","color":"primary","disabled":_vm.disableSearch}},[_vm._v("Search")])],1)],1)],1),_c('v-data-table-with-pagination',{class:{'cursor-pointer-row': !_vm.isCopyStakeRole},attrs:{"disable-sort":"","headers":_vm.headers,"items":_vm.clients,"server-items-length":_vm.total,"options":_vm.options,"loading":_vm.loadingUsers},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.createdAt || '-'))]}},{key:"item.walletUserid",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.id))]),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipBoard(item.id)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}},{key:"item.operatorUserId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.textOverflowMiddle(item.operatorUserId, 7, 5)))]),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipBoard(item.operatorUserId)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}},{key:"item.walletHash",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.textOverflowMiddle(item.walletHash, 7, 5)))]),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipBoard(item.walletHash)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}},{key:"item.staking_active",fn:function(ref){
var item = ref.item;
return [_c('v-badge',{attrs:{"color":item.h2eActive ? 'success' : 'error',"content":item.h2eActive ? 'ON' : 'OFF',"inline":""}})]}},{key:"item.profit_mining_active",fn:function(ref){
var item = ref.item;
return [_c('v-badge',{attrs:{"color":item.p2eActive ? 'success' : 'error',"content":item.p2eActive ? 'ON' : 'OFF',"inline":""}})]}},{key:"item.overallBalance",fn:function(ref){
var item = ref.item;
return [_c('OverallBalanceUser',{staticClass:"justify-end",attrs:{"user-id":item.id}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }